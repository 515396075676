import TagManager from 'react-gtm-module';

import React, {Component, Suspense, lazy} from 'react';

import '../../scss/styles.css';
import styles from '../../scss/global.module.scss';

import CircularProgress from '@material-ui/core/CircularProgress';

const Intro = lazy(()=> import ('../Intro'));
const Virtual360 = lazy(()=> import ('../Virtual360'));

const tagManagerArgs = { 
	gtmId: 'GTM-KLNDHGN'
}

TagManager.initialize(tagManagerArgs);

class App extends Component<any,any> {	
		
    render() {        
        return(<>
			<div id="App">
				<Suspense fallback={<CircularProgress className={styles.centerLoader} />}>
					<Intro/>
					<Virtual360/>
				</Suspense>
			</div>
        </>);
    }
}

export default App;