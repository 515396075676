const isiReducer = (state = false, action) => {
	switch(action.type){
		case 'SHOW_ISI':
			return !state;
		case 'HIDE_ISI':
			return false;
		default: 
			return state;
	}
}

export default isiReducer;