import {combineReducers} from 'redux';
import sceneReducer from './scene';
import loggedReducer from './logged';
import isiReducer from './isi';
import modalReducer from './modal';

const reducers = combineReducers({
	scene: sceneReducer,
	isLogged: loggedReducer,
	isISI: isiReducer,
	modal: modalReducer,
})

export default reducers;
